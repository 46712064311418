











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'RuleCardSection' })
export default class RuleCardSection extends Vue {
  @Prop({
    type: String,
    required: true,
  }) readonly label!: string
}
